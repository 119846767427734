import logo from '../logo/icon.svg';

import { NavLink } from 'react-router-dom';
import { ThemeToggle } from '../hooks/ThemeToggle';

export default function Header() {
    return (
        <div className="px-3.5 lg:px-0 py-3 border-b border-gray-200/60 dark:border-gray-800/90 duration-500">
            <div className="mx-auto max-w-7xl">
                <div className="flex items-center justify-between">
                    <NavLink to="/">
                        <div className="flex items-center space-x-2.5">
                            <img src={logo} className="h-5 w-5" alt="The Speeds Icon"/>
                            <h1 className="text-xl text-black/90 dark:text-white font-medium font-cabin duration-500">The Speeds</h1>
                        </div>
                    </NavLink>
                    <div className="flex items-center relative">
                        <ThemeToggle panelClassName="mt-1"/>
                    </div>
                </div>
            </div>
        </div>
    )
}