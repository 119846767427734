import { Component } from 'react';

import Header from './nav/Header';
import Footer from './nav/Footer';

import { Gauge } from './components/Gauge';
import { Helmet } from 'react-helmet';

export default class Speedtest extends Component {

    constructor(props) {
        super(props);

        this.state = {
            scale: [{
                degree: 680,
                value: 0
            }, {
                degree: 570,
                value: .5
            }, {
                degree: 460,
                value: 1
            }, {
                degree: 337,
                value: 10
            }, {
                degree: 220,
                value: 100
            }, {
                degree: 115,
                value: 500
            }, {
                degree: 0,
                value: 1E3
            }],
            ip: undefined,
            isp: undefined,
            current: "none",
            uRandom: 0,
            totalDownloadAmount: 0,
            totalUploadAmount: 0,
            finished: false,
            totalDone: 0,
            downloadSpeedMbps: 0,
            uploadSpeedMbps: 0,
            bonusT: 0,
            graceTimeDone: false,
            ulProgress: 0,
            failed: false,
            requests: []
        }

        this.startUpload = this.startUpload.bind(this);
        this.startDownload = this.startDownload.bind(this);
        this.uploadThread = this.uploadThread.bind(this);
        this.upload = this.upload.bind(this);
        this.reset = this.reset.bind(this);
    }

    componentDidMount() {
        this.mounted = true;

        this.getIPv4(ip => {
            if(this.mounted) {
                this.setState({ip: ip});
                if(ip.includes("Unknown")) {
                    this.setState({isp: "Turn Off Ad Blocker?"})
                } else {
                    this.getISP(ip, isp => this.setState({isp: isp}));
                }
            }
        });

        this.reset();
    }

    componentWillUnmount() {
        this.mounted = false;
        if(this.interval !== undefined)
            clearInterval(this.interval);
    }

    reset() {
        this.requests = [];

        this.setState({currentPerformance: new Date().getTime(), bonusT: 0, totalDownloadAmount: 0, totalUploadAmount: 0, totalDone: 0, finished: false, graceTimeDone: false, downloadSpeedMbps: 0, uploadSpeedMbps: 0, ulProgress: 0, failed: false});

        this.interval = setInterval(() => {
            if(this.state.current === "none" || this.state.current === "ping")
                return;

            const time = (new Date().getTime() - (this.state.currentPerformance || 0));
            const speed = (this.state.current === "download" ? this.state.totalDownloadAmount : this.state.totalUploadAmount) / (time / 1000.0);

            if (time < 200)
                return;

            if(this.state.graceTimeDone)
                this.setState({ulProgress: (time + this.state.bonusT) / 15 * 1000})

            if(!this.state.graceTimeDone) {
                if (time > 1000 * 1.5) {
                    if ((this.state.current === "download" ? this.state.totalDownloadAmount : this.state.totalUploadAmount) > 0) {
                        // if the connection is so slow that we didn't get a single chunk yet, do not reset
                        this.setState({currentPerformance: new Date().getTime(), bonusT: 0, [(this.state.current === "download" ? "totalDownloadAmount" : "totalUploadAmount")]: 0});
                    }
                    this.setState({graceTimeDone: true});
                }
            } else {
                //decide how much to shorten the test. Every 200ms, the test is shortened by the bonusT calculated here
                const bonus = (5.0 * speed) / 100000;

                this.setState(prevState => ({bonusT: prevState.bonusT + bonus > 400 ? 400 : bonus}));
                this.setState({[`${this.state.current}SpeedMbps`]: speed * 8 / 1000000});

                // Cancel test if it's been running for too long; 15 = 15 seconds
                if (((time + this.state.bonusT) / 1000.0) > 15 || this.state.failed) {
                    this.setState({finished: true});
                    this.clearRequests();
                    console.log(`Test Finished (${this.state.current})`);
                    if(this.state.current === "download") {
                        this.setState({bonusT: 0, currentPerformance: new Date().getTime(), graceTimeDone: false});
                        this.startUpload();
                    } else {
                        clearInterval(this.interval);
                        this.setState({current: "none"});
                        this.clearRequests();
                    }
                }
            }
        }, 200);
    }

    startTest() {
        this.startPing();
    }

    getIPv4(callback) {
        console.log("Getting IPv4", new Date().getTime());
        fetch("https://api.ipify.org")
            .then(res => res.text())
            .then(res => {
                callback(res);
            })
            .catch(() => {
                callback("Unknown IP");
            })
    }

    getISPRename(callback) {
        fetch(`./isp_rename.json`)
            .then(res => res.json())
            .then(res => {
                callback(res);
            })
            .catch(() => {
                callback([]);
            })
    }

    getISP(ip, callback) {
        fetch(`https://ip.eazyftw.com/api/ip/${ip}`)
            .then(res => res.json())
            .then(res => {
                let org = res.org.split(" ");
                org.shift();
                org = org.join(" ");
                this.getISPRename(rename => {
                    for (let i = 0; i < rename.length; i++) {
                        if(rename[i].type === "complete" && rename[i].name === org) {
                            org = rename[i].replace;
                        }
                    }

                    callback(org);
                })
            })
            .catch(() => {
                callback("Unknown ISP");
            })
    }

    clearRequests() {
        for (let i = 0; i < this.requests.length; i++) {
            try {
                this.requests[i].abort();
            } catch (e) {}
        }
        this.setState({requests: []});
    }

    uRandom(a, c) {
        for (var f = new Uint32Array(262144), e = [], w = 0; w < a; w++) {
            for (var m = w, p = f.length, z = 0; z < p; z++)
                f[z] = 4294967296 * Math.random();
            e[m] = f
        }
        return new Blob(e, {
            type: "application/octet-stream"
        }, c())
    };

    uploadThread() {
        for (let b = 0; b < 6; b++)
            setTimeout(function(l) {
                this.upload(l);
        }.bind(this), 300 * b)
    }

    downloadThread(size) {
        for (let b = 0; b < 6; b++)
            setTimeout(function(l) {
                if(b === 0)
                    this.setState({currentPerformance: new Date().getTime()})
                this.download(l, size);
            }.bind(this), 300 * b)
    }

    startPing() {
        this.reset();

        console.log("Starting Ping Test");
        this.setState({current: "ping", graceTimeDone: false, bonusT: 0});
        setTimeout(() => {
            this.ping();
        }, 50);
    }

    startDownload() {
        console.log("Starting Download Test");
        this.setState({current: "download", graceTimeDone: false, bonusT: 0});
        this.downloadThread(25);
    }

    startUpload() {
        console.log("Starting Upload Test");
        this.setState({current: "upload", graceTimeDone: false, bonusT: 0});

        const uRandom = this.uRandom(25, this.uploadThread);
        this.setState({uRandom: uRandom});
    }

    uuidv4() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    ping() {
        if(this.state.current !== "ping")
            return;

        let startTime = new Date().getTime();
        let prevTime = null;
        let ping = 0.0;
        let jitter = 0.0;
        let i = 0;
        let instspd = 0.0;
        let prevInstspd = 0;

        let doPing = function() {
            let pingR = new XMLHttpRequest();

            pingR.onload = (e) => {
                if(i === 0) {
                    prevTime = new Date().getTime(); // first pong
                } else {
                    let instspd = new Date().getTime() - prevTime;

                    try {
                        //try to get accurate performance timing using performance api
                        let p = performance.getEntries();
                        p = p[p.length - 1];
                        let d = p.responseStart - p.requestStart;
                        if (d <= 0) d = p.duration;
                        if (d > 0 && d < instspd)
                            instspd = d;
                    } catch (e) { }

                    if (instspd < 1) instspd = prevInstspd;
                    if (instspd < 1) instspd = 1;
                    let instjitter = Math.abs(instspd - prevInstspd);
                    if (i === 1) ping = instspd;
                    /* first ping, can't tell jitter yet*/ else {
                        if (instspd < ping) ping = instspd; // update ping, if the instant ping is lower
                        if (i === 2) jitter = instjitter;
                        //discard the first jitter measurement because it might be much higher than it should be
                        else jitter = instjitter > jitter ? jitter * 0.3 + instjitter * 0.7 : jitter * 0.8 + instjitter * 0.2; // update jitter, weighted average. spikes in ping values are given more weight.
                    }

                    prevInstspd = instspd;
                }

                this.setState({ping: ping.toFixed(2), jitter: jitter.toFixed(2)});

                i++;

                if (i < 10) doPing();
                else {
                    this.startDownload();
                }
            }

            pingR.onerror = (e) => {
                doPing();
            }

            pingR.open("GET", "https://st-cam.midco.net.prod.hosts.ooklaserver.net:8080/hello?nocache=" + this.uuidv4(), true);
            // this.setState({currentPerformance: window.performance.now()});

            pingR.send();

            this.requests.push(ping);
        }.bind(this);

        doPing();
    }

    download(l, size) {
        if(this.state.finished || this.state.current !== "download")
            return;

        let download = new XMLHttpRequest();

        let prevLoaded = 0;

        download.open("GET", "https://st-cam.midco.net.prod.hosts.ooklaserver.net:8080/download?nocache=" + this.uuidv4() + "&size=" + (size * 1000000), true);
        // this.setState({currentPerformance: window.performance.now()});

        download.onprogress = (e) => {
            const loadDiff = e.loaded <= 0 ? 0 : e.loaded - prevLoaded;

            if (isNaN(loadDiff) || !isFinite(loadDiff) || loadDiff < 0) // just in case
                return;

            prevLoaded = e.loaded;

            this.setState(prevState => ({totalDownloadAmount: loadDiff + prevState.totalDownloadAmount}));
        }
        download.onload = (e) => {
            this.download(l, size);
        }
        download.onerror = (e) => {
            this.download(l, size);
        }

        download.setRequestHeader("Content-Encoding", "identity");
        download.setRequestHeader("Content-Type", "application/octet-stream");
        download.send();

        this.requests.push(download);
    }

    upload(l) {
        if(this.state.current !== "upload")
            return;

        let upload = new XMLHttpRequest();

        let prevLoaded = 0;

        upload.open("POST", "https://st-cam.midco.net.prod.hosts.ooklaserver.net:8080/upload?nocache=" + this.uuidv4(), true);
        // this.setState({currentPerformance: window.performance.now()});

        upload.upload.onprogress = (e) => {
            const loadDiff = e.loaded <= 0 ? 0 : e.loaded - prevLoaded;

            if (isNaN(loadDiff) || !isFinite(loadDiff) || loadDiff < 0) // just in case
                return;

            prevLoaded = e.loaded;

            this.setState(prevState => ({totalUploadAmount: loadDiff + prevState.totalUploadAmount}));
        }
        upload.upload.onload = (e) => {
            this.upload(l);
        }
        upload.upload.onerror = (e) => {
            this.upload(l);
        }

        upload.setRequestHeader("Content-Encoding", "identity");
        upload.setRequestHeader("Content-Type", "application/octet-stream");
        upload.send(this.state.uRandom);

        this.requests.push(upload);
    }

    isDarkTheme() {
        return localStorage.theme === 'dark' ||
            (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches);
    }

    render() {
        return (
            <div className="antialiased flex transition-colors duration-500 flex-col text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-900 min-h-screen">
                <div className="flex-grow">
                    <Helmet>
                        <meta name="theme-color" content={this.isDarkTheme() ? "#111826" : "#FFFFFF"} />
                    </Helmet>
                    <Header/>

                    <div className="mx-auto max-w-8xl pt-12 pb-6">
                        {this.state.current !== "none" ? (
                            <Gauge label="MB/s" value={this.state.current === "download" ? this.state.downloadSpeedMbps.toFixed(2) : this.state.uploadSpeedMbps.toFixed(2)} max={1000}/>
                        ) : (
                            <>
                                {this.state.finished ? (
                                    <div className="font-light">
                                        <div className="font-medium text-gray-700 dark:text-white">Results:</div><br/>

                                        <span className="font-normal">Download:</span> {this.state.downloadSpeedMbps}<br/>
                                        <span className="font-normal">Upload:</span> {this.state.uploadSpeedMbps}
                                    </div>
                                ) : (
                                    <div className="flex justify-center w-full" onClick={() => this.startTest()}>
                                        <button className="text-gray-800 dark:text-white font-medium text-8xl py-[4.6rem] px-10 rounded-full border-2 border-ez hover:bg-ez/10">
                                            GO!
                                        </button>
                                    </div>
                                )}
                            </>
                        )}

                        {this.state.ping !== undefined && this.state.jitter !== undefined && (
                            <div className="text-center pt-3">
                                Ping: {this.state.ping}ms<br/>
                                Jitter: {this.state.jitter}ms<br/>
                            </div>
                        )}

                        <div className="grid grid-cols-1 lg:mx-0 lg:grid-cols-4 lg:gap-16 px-4 lg:px-0 pt-12">
                            <div className="hidden lg:block"/>
                            <div className="flex flex-row-reverse lg:flex-row justify-end items-center space-x-reverse space-x-4 lg:space-x-4 lg:text-right">
                                <div>
                                    <div className="text-gray-800 dark:text-white font-semibold text-lg">{this.state.isp}</div>
                                    <div className="font-medium text-lg">{this.state.ip}</div>
                                </div>
                                <div className="border dark:border-gray-200/70 rounded-full p-1.5">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="mt-4 lg:mt-0 flex items-center space-x-4">
                                <div className="border dark:border-gray-200/70 rounded-full p-1.5">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                                    </svg>
                                </div>
                                <div>
                                    <div className="text-gray-800 dark:text-white font-semibold text-lg">Midco</div>
                                    <div className="font-medium text-lg">Cambridge, MN</div>
                                </div>
                            </div>
                            <div className="hidden lg:block"/>
                        </div>
                    </div>
                </div>

                <Footer/>

                {/*{this.state.ip || ""}<br/>*/}


                {/*Ping: {this.state.ping || 0}ms<br/>*/}
                {/*Jitter: {this.state.jitter || 0}ms<br/><br/>*/}

                {/*Download Speed: {this.state.downloadSpeedMbps.toFixed(2) || 0} ({(this.state.totalDownloadAmount / 1000000).toFixed(2)} Mbps total)<br/>*/}
                {/*Upload Speed: {this.state.uploadSpeedMbps.toFixed(2) || 0} ({(this.state.totalUploadAmount / 1000000).toFixed(2)} Mbps total)<br/>*/}

                {/*{this.state.finished && <h1 className="font-semibold"><br/>Finished!</h1>}*/}
            </div>
        );
    }
}