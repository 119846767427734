import React  from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';

import './index.css';

import Speedtest from './Speedtest';
ReactDOM.render(
    <BrowserRouter>
        <Speedtest/>
    </BrowserRouter>,
    document.getElementById('root')
);
