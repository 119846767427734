import React from "react"
import { arc } from "d3-shape"
import { scaleLinear } from "d3-scale"
import { format } from "d3-format"

export const Gauge = ({
                   value = 50,
                   min = 0,
                   max = 100,
                   type = "download",
                   label,
                   units,
               }) => {

    const backgroundArc = arc()
        .innerRadius(1)
        .outerRadius(1.1)
        .startAngle(-Math.PI / 2)
        .endAngle(Math.PI / 2)
        .cornerRadius(0.02)
        ()

    const percentScale = scaleLinear()
        .domain([min, max])
        .range([0, 1])
    const percent = percentScale(value)

    const angleScale = scaleLinear()
        .domain([0, 1])
        .range([-Math.PI / 2, Math.PI / 2])
        .clamp(true)
    const angle = angleScale(percent)
    const filledArc = arc()
        .innerRadius(0.995)
        .outerRadius(1.105)
        .startAngle((-Math.PI / 2) - 0.01)
        .endAngle((angle) + 0.01)
        .cornerRadius(0.02)
        ()
    const colorScale = scaleLinear()
        .domain([0, 1])
        .range(["#288B58", "#1522F1"])
    const gradientSteps = colorScale.ticks(10)
        .map(value => colorScale(value))
    const markerLocation = getCoordsOnArc(
        angle,
        1 - ((1 - 1.12) / 2),
    )

    return (
        <div
            style={{
                textAlign: "center",
            }}>
            <svg style={{overflow: "visible", margin: "auto", zIndex: "1"}}
                 width="14em"
                 viewBox={[
                     -1, -1,
                     2, 1,
                 ].join(" ")}>
                <defs>
                    <linearGradient
                        id="Gauge__gradient"
                        gradientUnits="userSpaceOnUse"
                        x1="-1"
                        x2="1"
                        y2="0">
                        {gradientSteps.map((color, index) => (
                            <stop
                                key={color}
                                stopColor={color}
                                offset={`${
                                    index
                                    / (gradientSteps.length - 1)
                                }`}
                            />
                        ))}
                    </linearGradient>
                </defs>
                <path
                    d={backgroundArc}
                    fill="#dbdbe7"
                />
                <path
                    d={filledArc}
                    fill="url(#Gauge__gradient)"
                />
                {/*<line*/}
                {/*    y1="-1.12"*/}
                {/*    y2="-0.65"*/}
                {/*    stroke="white"*/}
                {/*    strokeWidth="0.027"*/}
                {/*/>*/}
                {/*<circle*/}
                {/*    cx={markerLocation[0]}*/}
                {/*    cy={markerLocation[1]}*/}
                {/*    r="0.1"*/}
                {/*    stroke="#2c3e50"*/}
                {/*    strokeWidth="0.01"*/}
                {/*    fill={colorScale(percent)}*/}
                {/*/>*/}
                <path
                    d="M0.136364 0.0290102C0.158279 -0.0096701 0.219156 -0.00967009 0.241071 0.0290102C0.297078 0.120023 0.375 0.263367 0.375 0.324801C0.375 0.422639 0.292208 0.5 0.1875 0.5C0.0852272 0.5 -1.8346e-08 0.422639 -9.79274e-09 0.324801C0.00243506 0.263367 0.0803571 0.120023 0.136364 0.0290102ZM0.1875 0.381684C0.221591 0.381684 0.248377 0.356655 0.248377 0.324801C0.248377 0.292947 0.221591 0.267918 0.1875 0.267918C0.153409 0.267918 0.126623 0.292947 0.126623 0.324801C0.126623 0.356655 0.155844 0.381684 0.1875 0.381684Z"
                    transform={`rotate(${
                        angle * (180 / Math.PI)
                    }) translate(-0.2, -0.33)`}
                    fill="#257876"
                />
            </svg>
            <div style={{
                marginTop: "1em",
                color: "white",
                fontSize: "3em",
                lineHeight: "1em",
                fontWeight: "500",
                fontFeatureSettings: "'zero', 'tnum' 1",
            }}>
                 {format(",")(value)}
            </div>
            {!!label && (
                <div style={{
                    color: "#8b8ba7",
                }}>
                    {/*{type === "download" ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">*/}
                    {/*    <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75" />*/}
                    {/*</svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">*/}
                    {/*    <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m0-3l-3-3m0 0l-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75" />*/}
                    {/*</svg>*/}
                     {label}
                </div>
            )}
            {/*{!!units && (*/}
            {/*    <div style={{*/}
            {/*        color: "#8b8ba7",*/}
            {/*        lineHeight: "1.3em",*/}
            {/*        fontSize: "30",*/}
            {/*        fontWeight: "300",*/}
            {/*    }}>*/}
            {/*        {units}*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    )
}
const getCoordsOnArc = (angle, offset = 10) => [
    Math.cos(angle - (Math.PI / 2)) * offset,
    Math.sin(angle - (Math.PI / 2)) * offset,
]